import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { ProjectLink, Spinner } from '../../components';
import { ProjectEntity, ReportEntity, ReportWithProject } from './types';
import { actionCreators } from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import PrintReportDialog from './child-components/printReportDialog'
import DetailedCalculationPrintDialog from './child-components/detailedCalculationPrintDialog'
import { ReportApi, DetailedCalculationApi, SubscriptionApi } from '../../api';

type ReportProps = typeof actionCreators &
{
   readonly userName: string;
};

const Report: React.FC<ReportProps> = ({
   userName
}) => {

   const [isLoading, setIsLoading] = useState(true);
   const [modules, setModules] = useState([]);

   const [report, setReport] = useState<ReportEntity | null>();
   const [project, setProject] = useState<ProjectEntity | null>();
   const [templateName, setTemplateName] = useState('');
   const params = useParams();
   const reportId = (params as any).id || 0;
   const [openPrintDialog, setOpenPrintDialog] = useState(false);
   const [isSharedReport, setIsSharedReport] = useState(false);
   const [openDetailedPrintDialog, setOpenDetailedPrintDialog] = useState(false);
   const [enableAi, setEnableAi] = useState(false);


   useEffect(() => {




      const loadData = async (moduleName: string) => {

         if (!moduleName)
            return null;
         const data = await import(`../Modules/${moduleName}/${moduleName}`);
         return data;
      };

      if (reportId) {
         ReportApi.getReport(reportId).then((response: { data: ReportWithProject }) => {
            const { data } = response || {};

            if (data) {
               const loadedModules = data.report ? data.report.reportModules : [];
               const project = data.project ? data.project : null;
               setReport(data.report);
               setTemplateName(data.reportTemplateName);
               setProject(data.project);
               checkOwner(data.project.userName);
               GetAISubscription(data.project.userId);
               var loaded = 0;
               loadedModules.forEach(module => {

                  var data = loadData(module.pathToReactComponent).then(
                     (response) => {
                        if (response != null) {
                           var obj = {
                              module: module,
                              data: response
                           }

                           setModules(old => [...old, obj]);
                           loaded++;
                           if (loaded == loadedModules.length) {
                              setIsLoading(false);
                           }
                        }
                     }
                  );
               })
            }

         })
      }
   }, [reportId])

   const checkOwner = (ownerName) => {
      if (ownerName != userName && ownerName != null) {
         setIsSharedReport(true)
      }
   }

   const GetAISubscription = (userId) => {
      SubscriptionApi.getSubscription(userId, 1)
         .then(
            (response) => {
               setEnableAi(response.data);
            },
            (error) => {
               console.log(error);
            })
   }

   const openPrintDialogClick = () => {
      setOpenPrintDialog(true);
   }

   const handlePrintSelected = (selection) => {
      setOpenPrintDialog(false);
      if (selection == 1) {
         // Draft
         const repId = report ? report.reportId : 0;
         ReportApi.getPdf(repId, 0);
      }
      if (selection == 2) {
         // Publish
         const repId = report ? report.reportId : 0;
         ReportApi.getPdf(repId, 1);
      }
      if (selection == 3) {
         // Publish
         const repId = report ? report.reportId : 0;
         ReportApi.getPdf(repId, 2);
      }
   }

   const openDetailedPrintDialogClick = () => {
      setOpenDetailedPrintDialog(true);
   }
   const handleDetailedPrintSelected = (selection) => {
      setOpenDetailedPrintDialog(false);
      

      if (selection != 0) {
         const repId = report ? report.reportId : 0;
         var data = {
            reportId: repId,
            selectedPrintOption: selection
         }
         DetailedCalculationApi.setSelectedPrintOption(data)
            .then(
               (response) => {
                  ReportApi.getPdf(repId, 1);
               }
            );
      }

   }

   const handleSetStatus = (status) => {
      setReport({ ...report, state: status })

      var data = {
         reportId: report.reportId,
         state: status
      }
      ReportApi.updateReport(data);
   }

   const showOwner = (ownerName) => {
      if (ownerName != userName && ownerName != null && ownerName != '') {
         return <span className='bk-project-owner-title'>Bygningssakkyndig: {ownerName}</span>
      }
   }


   var displayModules = null;
   if (modules && !isLoading) {
      displayModules = modules.sort((a, b) => a.module.order - b.module.order).map((module, index) => {
         return (<div className="bk-module">{module ? <module.data.default
            moduleId={parseInt(module.module.reportModuleId)}
            collapsed={module.module.collapsed || false}
            displayHeader={module.module.displayHeader}
            header={module.module.header}
            mode={module.module.mode || 0}
            readOnly={report.state != 1}
            multiComment={module.module.multiComment}
            showSharedColor={isSharedReport}
            enableAi={enableAi}
         /> : <p></p>}</div>);
      });
   };


   return (
      <>
         <div className="container">
            <div className="bk-report-header">
               <div className="bk-report-title">
                  {templateName}
                  {project ? showOwner(project.userName) : ''}
               </div>
               {
                  project ?
                     <>
                        <ProjectLink id={project.projectId} address={project.address.address} zipCode={project.address.zipCode} city={project.address.city} />
                        <div className=''>
                           {report.reportTemplateId == 17 &&
                              <img alt="icon" className="bk-header-icon-printer-report" src="/Content/images/design/icon-print.svg"
                                 onClick={openDetailedPrintDialogClick} />
                           }
                           {report.reportTemplateId != 17 &&
                              <img alt="icon" className="bk-header-icon-printer-report" src="/Content/images/design/icon-print.svg"
                                 onClick={openPrintDialogClick} />
                           }
                        </div>
                        {/* <div className="flex-2"><BkButton status={report.state} statusChangedCb={handleSetStatus} ></BkButton></div> */}
                     </>
                     : ''
               }

            </div>
            {project && report.reportTemplateId == 23 &&
               <div className='bk-info-header'>
                  {/* Rapporten låses en måned etter generert PDF. For å lage ny rapport i dette tiltaket må du kopier forrige forrige rapport eller opprett ny rapport. */}
                  Rapporten låses en måned etter generert PDF. Ved ny befaring må siste genererte rapport kopieres.
               </div>}

            <div className='report-content'>
               {displayModules}
            </div>

            <Spinner isLoading={isLoading} />
         </div>

         <PrintReportDialog
            open={openPrintDialog}
            latestReleaseDate={report ? report.latestReleaseDate : null}
            handleSelectedCb={handlePrintSelected}
         />
         <DetailedCalculationPrintDialog
            open={openDetailedPrintDialog}
            handlePrintCb={handleDetailedPrintSelected}
         />
      </>
   )
}

const mapStateToProps = (state: IApplicationState) => ({
   userName: state.auth.userName,
});
export default connect(mapStateToProps, actionCreators)(Report);

