import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AutoTextBox, ContactButton } from '../'
import { BkButton } from '../'
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faExclamation, faExclamationCircle, faMicrochip } from '@fortawesome/free-solid-svg-icons'

export interface AiDialogProps {
    open: boolean;
    text: string;
    aiText: string;
    handleCancelCb: () => void;
    handleSaveCb: (text: string) => void;
}

const AiDialog = (props: AiDialogProps) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selected, setSelected] = useState(0);
    const [selectedText, setSelectedText] = useState('');
    const [text, setText] = useState('');
    const [aiText, setAiText] = useState('');

    const toastIdRef = useRef<string | number>('');

    const handleClose = () => {
        setSelected(0);
        props.handleCancelCb();
    };

    const handleSave = () => {
        if (selected !== 0) {
            var text = selectedText;
            setSelected(0);
            props.handleSaveCb(text);
        }
        else {
            if (!toast.isActive(toastIdRef.current)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Velg en tekst å beholde", faExclamationCircle)
                );
            }
        }
    }

    useEffect(() => {
        setOpenDialog(props.open);
        setText(props.text);
        setAiText(props.aiText);
    }, [props.open]);

    const handleChecked = (id, checked) => {
        setSelected(checked ? id : 0);
        if (id === 1) {
            setSelectedText(text);
        }
        else if (id === 2) {
            setSelectedText(aiText);
        }
    }
    const onChange = (value) => {
        setText(value);
    }
    const onChangeAi = (value) => {
        setAiText(value);
    }

    return (
        <>
            <Dialog open={openDialog} fullWidth={true} maxWidth={'lg'} onClose={handleClose} aria-labelledby="form-dialog-title" keepMounted={false}>
                <DialogTitle id="draggable-dialog-title" className="dialog-header">
                    AI-tekstforbedring
                </DialogTitle>
                <DialogContent>
                    <div className='module-row'>
                        <div className='container'>
                            <div className='module-row ms-1'>
                                <div className='me-3'>Original tekst</div>
                                <input
                                    type="checkbox"
                                    checked={selected === 1}
                                    onChange={(e) => handleChecked(1, e.target.checked)}
                                />
                            </div>
                            <div className='row'>
                                <AutoTextBox onValueChangeCb={onChange} class={selected === 1 ? 'selected' : ''} defaultRows={15} text={props.text} />
                            </div>
                        </div>

                        <div className='container'>
                            <div className='module-row ms-1'>
                                <div className='me-3'>AI tekst</div>
                                <input
                                    type="checkbox"
                                    checked={selected === 2}
                                    onChange={(e) => handleChecked(2, e.target.checked)}
                                />
                            </div>
                            <div className='row'>
                                <AutoTextBox onValueChangeCb={onChangeAi} class={selected === 2 ? 'selected' : ''} defaultRows={15} text={props.aiText} />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ContactButton caption='Avbryt' onClickHandler={handleClose} />
                    <BkButton caption='Legg til' onClickHandler={handleSave} />
                </DialogActions>
            </Dialog>
        </>
    )
}
export default React.memo(AiDialog);