import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class SubscriptionService extends BaseService {
   private static _subscriptionService: SubscriptionService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): SubscriptionService {
     return this._subscriptionService || (this._subscriptionService = new this('subscription'));
   }
 

   public async getSubscription(userId:number, subscriptionType:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!userId){
        this.handleAllErrors(` userId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + userId + '/' + subscriptionType, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async subscribe(data: {userId:number, subscriptionType:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for subscribe.`); 
         Promise.reject();
      }
      
      await this.$http.post('Subscribe',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async unsubscribe(data: {userId:number, subscriptionType:number}): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for unsubscribe.`); 
        Promise.reject();
      }
      
      await this.$http.post('Unsubscribe',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

}

export const SubscriptionApi = SubscriptionService.Instance;