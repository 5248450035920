import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Aos from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faArrowLeft, faFilePdf, faFile} from '@fortawesome/free-solid-svg-icons'


const ExamplesCalculation: React.FC = () => {

  const openPdf = (url) => {
    const win = window.open(url, "_blank");
    win.focus();
 }

  return (
    <div className='container'>
      <div className='row bk-row-header mb-3'>
        <div className='col-lg-10'> <div className=" bk-row-header-title "> Kalkulasjon Eksempel</div></div>
        <div className='col-lg-2'>
          <a href="/documents" className='dashboard-back-btn mx-auto'>
            <FontAwesomeIcon icon={faArrowLeft} className=" mt-3 me-3" />
            Hovedmeny </a></div>
      </div>
      
      <div className='btn_grid'>
     
               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Kalkulasjons-eksempler/1.pdf')}>
                  <FontAwesomeIcon icon={faFile} className="fa-2x" />
                  <div className='dashboard-btn-text '>1. Alle detaljer</div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Kalkulasjons-eksempler/2.pdf')}>
                  <FontAwesomeIcon icon={faFile} className="fa-2x" />
                  <div className='dashboard-btn-text '>2. Kolonne sum materiell, sum arbeid og sum post</div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Kalkulasjons-eksempler/3.pdf')}>
                  <FontAwesomeIcon icon={faFile} className="fa-2x" />
                  <div className='dashboard-btn-text '>3. Kolonne Sum post </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Kalkulasjons-eksempler/4.pdf')}>
                  <FontAwesomeIcon icon={faFile} className="fa-2x" />
                  <div className='dashboard-btn-text '>4. Kategori sum materiell, sum arbeid og sum post. Med poster</div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Kalkulasjons-eksempler/5.pdf')}>
                  <FontAwesomeIcon icon={faFile} className="fa-2x" />
                  <div className='dashboard-btn-text '>5. Kategori sum post. Med sum poster</div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Kalkulasjons-eksempler/6.pdf')}>
                  <FontAwesomeIcon icon={faFile} className="fa-2x" />
                  <div className='dashboard-btn-text '>6. Kategori sum materiell, sum arbeid og sum post. Skjul poster </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Kalkulasjons-eksempler/7.pdf')}>
                  <FontAwesomeIcon icon={faFile} className="fa-2x" />
                  <div className='dashboard-btn-text '>7. Kategori sum post. Skjul poster</div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Kalkulasjons-eksempler/8.pdf')}>
                  <FontAwesomeIcon icon={faFile} className="fa-2x" />
                  <div className='dashboard-btn-text '>8. Mengde materiell</div>
               </div>
     
        </div>
    </div>


  );
}
export default ExamplesCalculation;