import React, {useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faBars } from '@fortawesome/free-solid-svg-icons'
import { PhraseDialog } from './Dialogs'
import { PhraseApi } from '../api'
import { toXmlSafeText } from '../utils';



type SingleRowTextBoxProps = {
   readonly text: string;
   readonly phraseKey?:string;
   readonly class?:string;
   readonly id?:string;
   readonly focus?:boolean;
   readonly readOnly?:boolean;
   readonly addPadding?:boolean;
   readonly alphabetical?:boolean;
   readonly smallIcon?:boolean;
   readonly onValueChangeCb?: (value: string) => void;
   readonly onSelectedChangeCb?: (value: string) => void;
 };

const keyPressTimer = 1000;
let inputTimer = null;

function  SingleRowTextBox(props: SingleRowTextBoxProps) {


   const [text, setText] = useState('');
   const [selectedText, setSelectedText] = useState('');
   const [openPhraseDialog, setOpenPhraseDialog] = useState(false);
   const textBoxRef = useRef(null);
   const [key, setKey] = useState('');
   const [id, setId] = useState('auto');
   const [readOnly, setReadOnly] = useState(false);

   var init = 0;

   useEffect(() => {
      setKey(props.phraseKey);
      setText(props.text)
      if (props.id) {
         setId(props.id);
      }

      if (props.readOnly != null){
         setReadOnly(props.readOnly);
      }

   }, [props]);

   const onBlur = () => {
      if (inputTimer !== null){
         clearTimeout(inputTimer);
         props.onValueChangeCb(text);
      }
   }

   const onValueChange = (e) => {
      const { name, value } = e.target;
      setText(toXmlSafeText(value));
   
      if (inputTimer !== null){
         clearTimeout(inputTimer);
       }
       inputTimer = setTimeout(() => {
         updateValue(toXmlSafeText(value));
       }, keyPressTimer);  
   }

   const updateValue = (value) => {
      props.onValueChangeCb(value);
   }

   const openPhraseregister = () => {
      let cursorStart = textBoxRef.current.selectionStart;
      let cursorEnd = textBoxRef.current.selectionEnd;
      if (cursorEnd > cursorStart) {
         setSelectedText(text.substring(cursorStart,cursorEnd));
      }
      else {
         setSelectedText(text);
      }
      setOpenPhraseDialog(true);
   }


   const handlePhraseSave = (value) => {
      setOpenPhraseDialog(false);
      if (value == '') {
         return;
      }
      if (text== null || text==undefined || text=='') {
         setText(value);
         props.onValueChangeCb(value);
      }
      else {
         setText(text + ', ' + value);
         props.onValueChangeCb(text + ', ' + value);
      }
   }


   const handlePhraseCancel = () => {
      setOpenPhraseDialog(false);
   }


   // const savePhrase = () => {
   //    if (text== null || text==undefined || text=='') {
   //       //Nothing to save
   //       return;
   //    }

   //    var data = {
   //       phraseId:0,
   //       userId:0,
   //       key:props.phraseKey,
   //       text:text
   //    };
   //    PhraseApi.addPhrase(data)
   //    .then(
   //      (response) => {
   //         //ok
   //      },
   //      (error) => {
   //        //    
   //        console.log(error);
   //      });
   // }
 
   return <>
      <div className="auto-text-box-frame" key={id}>
         <div className="text-box">
            <input ref={textBoxRef} readOnly={readOnly} className={"form-control " + props.class} value={text} onChange={(e) => onValueChange(e)} onBlur={onBlur}/>
         </div>
         {(props.addPadding && props.phraseKey) ? 
            <>
            <div className="phrase-icon" ></div>
            </> : ''
         }
         {(props.phraseKey) ? 
            <>
            <div className="add-icon"> 
            <FontAwesomeIcon icon={faClipboardList}  onClick={openPhraseregister}/>
            </div>
            </> : ''
         }
      </div>

      <PhraseDialog
         open={openPhraseDialog}
         phraseKey={key}
         text={selectedText}
         singleLine={true}
         handleSaveCb = {handlePhraseSave}
         handleCancelCb = {handlePhraseCancel}
      />
     
   </>
}
  
export default SingleRowTextBox;