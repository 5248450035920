import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class MultilineInformationModuleService extends BaseService {
   private static _multilineInformationModuleService: MultilineInformationModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): MultilineInformationModuleService {
     return this._multilineInformationModuleService || (this._multilineInformationModuleService = new this('multilineInformationModule'));
   }
 

   public async getMultilineInformation(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addMultilineInformation(data: {moduleId:number}): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
       this.handleAllErrors(` Data was not provided for addMultilineInformation.`); 
       Promise.reject();
    }
    
    await this.$http.post('Add',data, {withCredentials: true})
    .then((response) => {
       axiosResponse = response;
    }, 
    (error) => {
          this.handleAllErrors(error); 
          throw (error);
    });

    return axiosResponse;
 }

   public async updateMultilineInformation(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateMultilineInformation.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 
}

export const MultilineInformationModuleApi = MultilineInformationModuleService.Instance;