import React from 'react';
import { BrowserRouter, Switch, Route, Redirect, Link } from "react-router-dom";
const AboutWeb: React.FC = ()=>{

    return (
        <div className="container about-container mt-2">
        <div className="about-flex-row mt-2 mx-2">
          <div className="">
            <div className="about-text">
              <div className="about-text-titel">Velkommen til Byggekost.no</div>
              Byggekost.no ble utviklet i 2013 med en en visjon om å gjøre teknisk kalkulasjon av eksisterende bygninger enklere og raskere. Etter flere års grundig utvikling og testing ble programmet tilgjengelig for et utvalg testpersoner i NITO-Takst i 2017. Allerede i 2018 ble Byggekost.no gjort tilgjengelig for takstmenn over hele Norge.
  
              Solid erfaring og bransjekompetanse.
              I dag eies Byggekost.no av Pro Verk AS, et selskap grunnlagt i 2018 av Erik Sørensen og Runar Gautestad. Begge har lang og solid erfaring fra byggebransjen (fra 1980) og takstbransjen (fra 1997). Med fagbrev og mesterbrev i tømrerfaget, teknisk fagskole og takstutdanning med tilhørende godkjenninger, bringer de unik kompetanse til utviklingen av Byggekost.no.
  
              Mer enn bare teknisk kalkulasjon
              Byggekost.no leverer i dag et bredt spekter av tjenester som dekker behovene til takstmenn over hele landet. I tillegg til teknisk kalkulasjon av bygninger, tilbyr vi skjematur og løsninger for:
              <div className="about-flex-row mt-2 mb-2">
                <ul>
                  <li>Arealrapport</li>
                  <li>Brevtakst</li>
                  <li>Byggelånskontroll</li>
                </ul>
                <ul>
                  <li>Forhåndstakst</li>
                  <li>Landbrukstakst</li>
                  <li>Næringstakst</li>
                </ul>
                <ul>
                  <li>Reklamasjonsrapport</li>
                  <li>Skaderapport</li>
                  <li>Tilbudsbrev</li>
                </ul>
                <ul>
                  <li>Tilstandsrapport</li>
                  <li>Tomtetakst</li>
                  <li>Vedlikeholdsrapport</li>
                </ul>
                <ul>
                  <li>Verditakst</li>
                  <li>Verdivurdering</li>
                </ul>
              </div>
              Skreddersydd for takstbransjen
              Våre produkter og tjenester er spesialutviklet for å forenkle og effektivisere arbeidshverdagen til takstmenn. Med Byggekost.no får du tilgang til et brukervennlig, pålitelig og nøyaktig verktøy som er utviklet av eksperter med inngående forståelse og kunnskap om bransjen.
  
              Opplev hvordan Byggekost.no kan forbedre din arbeidsflyt og bidra til økt kvalitet og effektivitet i dine tjenester.
  
              Ta kontakt i dag!
              Er du klar for å ta din takstvirksomhet til neste nivå? Ta kontakt med oss for en uforpliktende prat, og lær mer om hvordan Byggekost.no kan hjelpe deg!
  
              <div><img className="proverk-img" alt="Pro verk AS" src="\Content\images\design\Proverk_logo.svg" /><div>
  
                <div className="about-img-row mt-1 mb-5">
                  <div className="about-img-container">
                    <img alt="Runar Gautestad" src="\Content\images\bilde-runar.jpg" className="web-about-img" />
                    <div className="about-imagetext" >
                      Runar Gautestad
                    </div>
  
                  </div>
                  <div className="about-img-container ">
                    <img alt="Erik Sørensen" src="\Content\images\bilde-erik.jpg" className="web-about-img" />
                    <div className="about-imagetext">
                      Erik Sørensen
                    </div>
  
                  </div>
  
                </div>
  
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

}
export default AboutWeb;