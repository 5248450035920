import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { BkButton } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faPaperclip, faClipboardList, faTags, faScaleBalanced, faQuestionCircle, faLink,
   faBook,
   faAddressCard,
   faFolderOpen,
   faFolder
} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';

const Documents: React.FC = () => {
   const [documents, setDocuments] = useState([]);

   // Used for edit project data
   const history = useHistory();


   const openPriceGuide = () => {
      const win = window.open("/priceguide", "_self");
      win.focus();
   }

   const openDocument = (id) => {
      // const win = window.open("/document/" + id, "_self");
      const win = window.open("/About", "_self");
      win.focus();
   }


   const openPdf = (url) => {
      //var url =  '/docs/Veileder - Tilstandsrapport NS 3600.pdf';
      const win = window.open(url, "_blank");
      win.focus();
   }

   const openLinkPage = () => {
      const win = window.open("/links/", "_self");
      win.focus();
   }

   const openSupportPage = () => {
      const win = window.open("/support", "_self");
      win.focus();
   }

   const openExample = () => {
      const win = window.open("/examples", "_self");
      win.focus();
   }
   const openExampleCalculation = () => {
      const win = window.open("/examplesCalculation", "_self");
      win.focus();
   }

   return (
      <div className=" container bk-container">
         <div className="row mt-2 mb-3">
            <div className='col-md-6'>
               <div className="bk-row-header bk-row-header-title ">Dokumenter</div>
            </div>
         </div>

         <div className='row mt-3'>
            <div className='col-4'>

               <div className="dashboard-btn" onClick={() => openPdf('/docs/Egenerklæringsskjema.pdf')}>
                  <FontAwesomeIcon icon={faClipboardList} className="fa-2x flex-1" />
                  <div className='dashboard-btn-text flex-3'> Egenerklærings-skjema </div>
               </div>

            </div>
            <div className='col-4'>

               <div className="dashboard-btn" onClick={() => openPriceGuide()}>
                  <FontAwesomeIcon icon={faTags} className="fa-2x flex-1" />
                  <div className='dashboard-btn-text flex-3'> Materialliste med priser </div>
               </div>

            </div>
            <div className='col-4'>
               <div className="dashboard-btn" onClick={() => openDocument(10)}>
                  {/* <img id="bygge-btn" className=" mt-3 mb-2" src="\Content\images\design\BK_white.svg" /> */}
                  <FontAwesomeIcon icon={faAddressCard} className="fa-2x flex-1" />
                  <div className='dashboard-btn-text flex-3'> Om oss </div>
               </div>
            </div>

         </div>
         <div className='row mt-3'>
            <div className='col-4'>
               <div className="dashboard-btn" onClick={() => openDocument(8)}>
                  <FontAwesomeIcon icon={faScaleBalanced} className="fa-2x flex-1" />
                  <div className='dashboard-btn-text flex-3'> Personværn-erklæring </div>
               </div>
            </div>
            <div className='col-4'>
               <div className="dashboard-btn" onClick={() => openSupportPage()}>
                  <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x flex-1" />
                  <div className='dashboard-btn-text flex-3'> Veileder</div>
               </div>
            </div>
            <div className='col-4'>
               <div className="dashboard-btn" onClick={() => openLinkPage()}>
                  <FontAwesomeIcon icon={faBook} className="fa-2x flex-1" />
                  <div className='dashboard-btn-text flex-3'> Viktige linker </div>
               </div>
            </div>
         </div>


         <div className='row mt-3'>
            <div className='col-4'>
               <div className="dashboard-btn" onClick={() => openExample()}>
                  <FontAwesomeIcon icon={faFolderOpen} className="fa-2x flex-1" />
                  <div className='dashboard-btn-text flex-3'> Rapport Eksempel </div>
               </div>
            </div>
            <div className='col-4'>
               <div className="dashboard-btn" onClick={() => openExampleCalculation()}>
                  <FontAwesomeIcon icon={faFolderOpen} className="fa-2x flex-1" />
                  <div className='dashboard-btn-text flex-3'> Kalkulasjons Eksempel </div>
               </div>
            </div>
         </div>

      </div>
   )
}

export default Documents;