import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class AiService extends BaseService {
   private static _aiService: AiService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): AiService {
     return this._aiService || (this._aiService = new this('ai'));
   }
 

   public async getAiImprovedText(data): Promise<AxiosResponse> {
      var axiosResponse;
      if (!data){
         this.handleAllErrors(` Data was not provided for update.`); 
         Promise.reject();
      }
      
      await this.$http.post('getAiImprovedText',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }


}

export const AiApi = AiService.Instance;